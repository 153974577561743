$(document).ready(function() {
    var links = [
	{
            name: 'Jaccko',
            link: 'http://jaccko.xyz/'
        },
        {
            name: 'R1chy',
            link: 'http://steamcommunity.com/id/RiChOfficialAUDIR8'
        },
	{
            name: 'Ichigo',
            link: ''
        },
	{
            name: 'VeakWolf',
            link: 'http://veakwolf.xyz'
        },
	{
            name: 'MakyG',
            link: 'http://makyg.cf/'
        },
		{
            name: 'Molerfu',
            link: ''
        },
		{
            name: 'Cuye',
            link: 'http://steamcommunity.com/id/cuyeZ'
        },
		{
            name: '$AVI0R',
            link: 'http://steamcommunity.com/id/CSGOSpecs'
        },
	


    ];
 
    for(var i in links) {
        var link = links[i];

        $('#marquee').append('<a href="' + link.link + '">' + link.name + '</a>');

        var link = $('#marquee').children('a').last();
    
        link.mouseover(function() {
            $('#hover')[0].play();
        });

        link.mousedown(function() {
            $('#click')[0].play();
        });

        link.mouseup(function() {
            $('#release')[0].play();
        });
        
        if(i != links.length - 1) {
            $('#marquee').append(' · ');
        }
    }


    $('#marquee').marquee({
        duration: 20000,
        gap: 400,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: true
    });

    /*
    var audio = $('#audio');
    audio.volume = 0.75;
    */

});